import React from "react"
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Layout from "@components/layout"
import SEO from "@components/seo"
import Footer from "@components/Footer/Footer"

const UnauthorizedPage = () => (
  <Layout>
    <main className="grid-content">
      <div className="grid-content-inner">
        <div className="grid-container page">
          <SEO title="401: Unauthorized" />
          <h1>You are not authorized to see this page</h1>
          <p className="center">
            <AniLink
              fade
              to='/'
              className='return-to'
            >
              Return to the main page
            </AniLink>
          </p>
        </div>
      </div>
      <Footer />
    </main>
  </Layout>
)

export default UnauthorizedPage
